<template>
    <div>
        <a-form :form="form" @submit="handleSubmit">
            <a-row type="flex" :gutter="24">
                <a-col :span="24" :lg="24" class="mb-24">
                    <a-card :bordered="false" class="header-solid h-full"
                        :bodyStyle="{ paddingTop: 0, paddingBottom: '40px' }">
                        <template #title>
                            <h5 class="mb-0">Transportation details</h5>
                        </template>
                        <a-row :gutter="[12]">
                            <a-col :span="6">
                                <a-form-item class="mb-10" label="Title" :colon="false">
                                    <a-input v-model="title" placeholder="eg. Title" />
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-card>
                </a-col>
                <a-button type="primary" html-type="submit"> Submit </a-button>
            </a-row>
        </a-form>
    </div>
</template>

<script>
import transportationAPI from '../../api/transportation';
import router from '../../router';

export default {
    data() {
        return {
            title: '',
            transportationEditData: null,
            isEdit: false,
            form: this.$form.createForm(this, { title: "editTransportation" }),
        };
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, transportation) => {
                if (!err) {
                    transportation.title = this.title;
                    if (!this.isEdit) {
                        transportationAPI.createTransportation(transportation).then((response) => {
                            if (response.status == 200) {
                                router.push('/transportations/transportations-list')
                            }
                        }).catch((error) => {
                            console.log(error);
                            router.push('/transportations/transportations-list');
                        })
                    } else {
                        transportationAPI.updateTransportation(this.transportationEditData.data.id, transportation).then((response) => {
                            if (response.status == 200) {
                                router.push('/transportations/transportations-list')
                            }
                        }).catch((error) => {
                            console.log(error);
                            router.push('/transportations/transportations-list');
                        })
                    }
                }
            });
        },
        async getTransportationById(id) {
            return transportationAPI.getTransportation(id);
        }
    },
    async mounted() {
            if (this.$route.params.id !== undefined) {
            this.transportationEditData = await this.getTransportationById(this.$route.params.id); // get transportation title by id to edit it
            this.isEdit = true;
            if (this.transportationEditData.code == "SUCCESS") {
                this.title = this.transportationEditData.data.title;
            }
        }
    },
};
</script>
