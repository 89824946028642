import { render, staticRenderFns } from "./EditTransportation.vue?vue&type=template&id=61a77308"
import script from "./EditTransportation.vue?vue&type=script&lang=js"
export * from "./EditTransportation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports