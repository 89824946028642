import client from "./client";

const getTransportation = (id) => {
  return client
    .get(`/transportation/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => error.response);
};

const getTransportations = (filter) => {
  return client
    .get(`/transportations`, filter)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => error.response);
};

const createTransportation = (data) => {
  return client
    .post("/transportation", data)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

const updateTransportation = (id, data) => {
  return client
    .put(`/transportation/${id}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

const deleteTransportations = (data) => {
  return client
    .put(`/transportation`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

const transportationAPI = {
  getTransportation,
  createTransportation,
  updateTransportation,
  deleteTransportations,
  getTransportations,
};

export default transportationAPI;
